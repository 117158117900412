<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>Post Category Edit</v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation class="multi-col-validation">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="item.name"
                    label="Name"
                    outlined
                    dense
                    placeholder="Name"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="statuses" label="Status" v-model="item.status" dense outlined></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input accept="image/*" @change="setImg" v-model="item.new_image" label="Image" outlined dense></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img :src="item.image"
                    class="preview-img"
                    v-if="item.image" />
                </v-col>

                <v-col cols="12" md="6">
                  <v-dialog ref="dialogDOB" v-model="isDate" :return-value.sync="item.date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="[rules.required]"
                        v-model="computedDateFormattedMomentjs"
                        label="Date Of Birth"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="item.date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="isDate = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.dialogDOB.save(item.date)"> OK </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    :items="userCats"
                    item-text="cname"
                    item-value="item_id"
                    label="Category Related to"
                    v-model="item.relatedto"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="limits" label="How Many Post Display ?" v-model="item.post_display" dense outlined></v-select>
                </v-col>

                <v-col cols="12">
                  <v-btn @click="submit" :loading="process" color="primary"> Submit </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
export default {
  name: 'PostCategoryEdit',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  data() {
    return {
      item: {},
      isDate: false,
      userCats: [],
      statuses: [
        {
          text: 'Inactive',
          value: '0',
        },
        {
          text: 'Active',
          value: '1',
        },
        {
          text: 'Deleted',
          value: '0',
        },
      ],
      limits: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: '7',
          value: '7',
        },
        {
          text: '14',
          value: '14',
        },
      ],
      process: false,
      rules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
    computedDateFormattedMomentjs() {
      return this.item.date ? moment(this.item.date).format('DD-MM-YYYY') : ''
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      var url = 'post-categories/' + this.$route.params.id
      this.$api.get(url).then(response => {
        this.item = response.data.data;
        this.userCats = response.data.user_categories;
        this.userCats.unshift({
          item_id: '0',
          cname: 'General'
        })

        if (this.item.date == null) {
          this.item.date = this.formatDate(new Date())
        } else {
          this.item.date = this.formatDate(this.item.date)
        }

        if (this.item.image != null) {
          this.item.image = process.env.VUE_APP_MEDIA_URL + this.item.image
        }
      })
    },
    setImg() {
      if (this.item.new_image == null) {
        this.item.image = null
      } else {
        this.item.image = URL.createObjectURL(this.item.new_image)
      }
    },
    formatDate(date) {
      return format(parseISO(new Date(date).toISOString()), 'yyyy-MM-dd')
      //  [year, month, day].join('-')
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.process = true
        this.item._method = 'PUT'
        const options = {
          indices: false,
        }
        const formData = serialize(this.item, options)
        this.$api
          .post('post-categories/' + this.item.id, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'PostCategoryList' })
            this.process = false
          }).catch(()=>{
            this.process = false
          });
      }
    },
  },
}
</script>
